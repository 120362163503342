import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';

export class AppAnalytics {
  constructor(
    readonly analytics: AnalyticsBrowser,
    readonly orgId: string | undefined,
    readonly state: 'logged-in' | 'logged-out',
    readonly deduplicateSet: Set<string> = new Set()
  ) {}

  trackNavItemClicked(title: string, href: string): void {
    this.analytics.track(
      'Nav Item Clicked',
      this.buildEventProps({
        title,
        href,
      })
    );
  }

  trackSearchQueried(query: string): void {
    this.analytics.track(
      'Search Queried',
      this.buildEventProps({
        query,
      })
    );
  }

  trackPlayGameClicked(
    context: 'featuredGamePack' | 'gamePackDetail' | 'gamePackHovered',
    gamePackId: string
  ) {
    this.analytics.track(
      'Play Game Clicked',
      this.buildEventProps({
        context,
        gamePackId,
      })
    );
  }

  trackFeaturedGamePackCarouselClick(
    context: 'nextButton' | 'prevButton' | 'bgCard'
  ) {
    this.analytics.track(
      'Featured Game Pack Carousel Clicked',
      this.buildEventProps({
        context,
      })
    );
  }

  trackLibraryFiltered(props: EventProperties) {
    this.analytics.track('Library Filtered', this.buildEventProps(props));
  }

  trackLibraryCategoryClicked(categoryName: string) {
    this.analytics.track(
      'Library Category Clicked',
      this.buildEventProps({
        categoryName,
      })
    );
  }

  trackLibraryGamePackClicked(props: EventProperties) {
    this.analytics.track(
      'Library Game Pack Clicked',
      this.buildEventProps(props)
    );
  }

  trackLibraryGamePackViewed(props: EventProperties, deduplicateKey: string) {
    if (this.deduplicateSet.has(deduplicateKey)) return;
    this.deduplicateSet.add(deduplicateKey);
    // 1% sampling in case of too many events
    // if (Math.random() > 0.01) return;
    this.analytics.track(
      'Library Game Pack Viewed',
      this.buildEventProps(props),
      {}
    );
  }

  trackLibraryCategoryCardViewed(
    props: EventProperties,
    deduplicateKey: string
  ) {
    if (this.deduplicateSet.has(deduplicateKey)) return;
    this.deduplicateSet.add(deduplicateKey);
    this.analytics.track(
      'Library Category Card Viewed',
      this.buildEventProps(props)
    );
  }

  trackLibraryCategoryCardClicked(props: EventProperties) {
    this.analytics.track(
      'Library Category Card Clicked',
      this.buildEventProps(props)
    );
  }

  trackLibraryGameDetailsClicked(
    packId: string,
    packName: string,
    action:
      | 'Schedule'
      | 'Play'
      | 'Continue Booking'
      | 'Get Started'
      | 'Try Free Demo'
      | 'Contact Us'
      | 'See Instant Quote'
      | 'Want Unlimited Events'
  ) {
    this.analytics.track(
      'Library Game Details Clicked',
      this.buildEventProps({
        packId,
        packName,
        action,
      })
    );
  }

  trackSectionNavClicked(props?: EventProperties) {
    this.analytics.track('Section Nav Clicked', this.buildEventProps(props));
  }

  trackSectionVideoPlayed(props?: EventProperties) {
    this.analytics.track('Section Video Played', props);
  }

  trackSectionLinkClicked(props?: EventProperties) {
    this.analytics.track('Section Link Clicked', props);
  }

  trackProgramCardClicked(props?: EventProperties) {
    this.analytics.track('Program Card Clicked', props);
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
      state: this.state.replace('-', ' '),
    };
  }
}
